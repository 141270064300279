import $ from 'jquery'

class Carousel {

  constructor() {
    this.$prevControl = $('._carousel_prev');
    this.$nextControl = $('._carousel_next');
    this.$container = $('._carousel_container');
    this.enable();
  }

  next() {
    let $first = this.$container.children().eq(0),
        width = $first[0].clientWidth,
        marginLeft = parseInt($first.css('marginLeft')),
        marginRight = parseInt($first.css('marginRight')),
        newMarginLeft = marginLeft - marginRight - width;
    this.disable();
    $first[0].animate(
      [{ marginLeft: `${marginLeft}px` }, { marginLeft: `${newMarginLeft}px` }],
      { duration: 600, iterations: 1, fill: 'forwards', easing: 'ease-out' }
    ).finished.then(() => {
      this.$container.append($first);
      $first[0].animate([{ marginLeft: `${newMarginLeft}px` }, { marginLeft: `${marginLeft}px` }], { duration: 1, iterations: 1, fill: 'forwards' });
      this.enable();
    });
  }

  prev() {
    let $last = this.$container.children().eq(this.$container.children().length - 1),
        width = $last[0].clientWidth,
        marginLeft = parseInt($last.css('marginLeft')),
        marginRight = parseInt($last.css('marginRight')),
        newMarginLeft = marginLeft - marginRight - width;
    this.disable();
    $last.css({ marginLeft: `${newMarginLeft}px` });
    this.$container.prepend($last);
    $last[0].animate(
      [{ marginLeft: `${newMarginLeft}px` }, { marginLeft: `${marginLeft}px` }],
      { duration: 600, iterations: 1, fill: 'forwards', easing: 'ease-out' }
    ).finished.then(this.enable.bind(this));
  }

  enable() {
    this.$prevControl.on('click', this.prev.bind(this));
    this.$nextControl.on('click', this.next.bind(this));
  }

  disable() {
    this.$prevControl.off('click');
    this.$nextControl.off('click');
  }

}

export default Carousel;