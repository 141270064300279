import $ from 'jquery'

class ModalPlayer {

  constructor() {
    let $wrapper = $('.page-wrapper');
    $wrapper.on('click', '[data-modal-player]', (e) => this.showModal(e));
    $wrapper.on('click', '.modal-player', (e) => this.hideModal(e));
    this.defs = {
      video: {
        baseUrl: 'https://www.ewtn.com/api/player/',
        player: '<div style="position: relative; display: block; max-width: 100%;"><div style="padding-top: 56.25%;"><iframe src="{src}" allowfullscreen webkitallowfullscreen mozallowfullscreen allow="encrypted-media" style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe></div></div>',
        setup: () => {}
      },
      playlist: {
        baseUrl: 'https://www.ewtn.com/api/player/',
        player: '<div style="position: relative; display: block; max-width: 100%;"><div style="padding-top: 56.25%;"><iframe src="{src}" allowfullscreen webkitallowfullscreen mozallowfullscreen allow="encrypted-media" style="position: absolute; top: 0px; right: 0px; bottom: 0px; left: 0px; width: 100%; height: 100%;"></iframe></div></div>',
        setup: () => {}
      },
      vurl: {
        baseUrl: '',
        player: '<video controls autoplay width="100%" height="100%"><source src="{src}"></video>',
        setup: () => {}
      },
      vimeo: {
        baseUrl: 'https://player.vimeo.com/video/',
        player: '<iframe style="height: 360px; width: 480px;" src="{src}?autoplay=1" id="media_vimeo_65804463_1_iframe" class="vimeo-player" type="text/html" frameborder="0" height="360" width="480"></iframe>',
        setup: () => {}
      },
      youtube: {
        baseUrl: 'https://www.youtube.com/embed/',
        player: '<iframe width="1016" height="762" src="{src}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        setup: () => {}
      }
    };
  }

  buildModal(type, mediaId) {
    let $modal = $('<div>').addClass('modal-player modal-player--theme--main'),
        $container = $('<div>').addClass('modal-player__container');
    $modal.append($('<span>').addClass('modal-player__close-button').append($('<span>').addClass('modal-player__close-button-x')));
    $container.append(this.defs[type].player.replace('{src}', this.defs[type].baseUrl + mediaId));
    this.defs[type].setup($container);
    return $modal.append($container);
  }

  showModal(e) {
    e.stopPropagation();
    let $target = $(e.currentTarget),
        $modal = this.buildModal($target.data('modal-player'), $target.data('media-id')),
        $container = $modal.find('.modal-player__container');
    $('.page-wrapper').append($modal);
    $container.css('margin-top', (document.documentElement.clientHeight - parseFloat($container.css('height'))) / 2 + 'px');
  }

  hideModal(e) {
    if (e.target.tagName.toLowerCase() == 'audio') return; // Safari audio element propagates click events; don't destroy modal if user/player interaction
    e.stopPropagation();
    $('.page-wrapper > .modal-player').remove();
  }
}

export default ModalPlayer;
